@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: jetbrains mono;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  color: white;
  background-image: linear-gradient(45deg, gray 5%, transparent 5%), linear-gradient(-45deg, gray 5%, transparent 5%), linear-gradient(45deg, transparent 95%, gray 95%), linear-gradient(-45deg, transparent 98%, gray 95%);
  background-size: 10px 10px;
    background-position: 0 0, 1px 0, 1px -1px, 0px -1px;
}

body:hover {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  border: solid white 1px;
}

.ultraFont {
  font-family: ultra;
}


.divider {                /* minor cosmetics */
  display: table; 
  text-align: center; 
  width: 75%;             /* divider width */
  margin: 40px auto;          /* spacing above/below */
}
.divider span { display: table-cell; position: relative; }
.divider span:first-child, .divider span:last-child {
  width: 50%;
  top: 13px;              /* adjust vertical align */
  -moz-background-size: 100% 2px;   /* line width */
  background-size: 100% 2px;      /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {       /* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#FFFFFF));
  background-image: -webkit-linear-gradient(180deg, transparent, #FFFFFF);
  background-image: -moz-linear-gradient(180deg, transparent, #FFFFFF);
  background-image: -o-linear-gradient(180deg, transparent, #FFFFFF);
  background-image: linear-gradient(90deg, transparent, #FFFFFF);
}
.divider span:nth-child(2) {
  color: #FFFFFF; padding: 0px 5px; width: auto; white-space: nowrap;
}
.divider span:last-child {        /* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#FFFFFF), to(transparent));
  background-image: -webkit-linear-gradient(180deg, #FFFFFF, transparent);
  background-image: -moz-linear-gradient(180deg, #FFFFFF, transparent);
  background-image: -o-linear-gradient(180deg, #FFFFFF, transparent);
  background-image: linear-gradient(90deg, #FFFFFF, transparent);
}

.webb {
  fill: white;
}

/* #mee {
  filter:  invert(0.7);
} */

.background-div {
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets//images/shepherd.png');
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */

}

/* styles.css */
.hover-background-image:hover {
  background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url('https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQP5QQKcY4t1-_XAOvt_5Ii9LGJqTDX0B7u5sOZJFeU8QCGJ2jReifGEDftXkScCw-lMm8nmFUYF2QXwMR2KrzTsw');
  background-size: cover;
  background-position: center;
  
}
